import { createRouter, createWebHashHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';

const routes = [
  {
    path: '/',
    name: 'Front Layout',
    component: () => import('@/views/front/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/front/Home.vue'),
      },
      {
        path: 'city',
        name: 'CityLayout',
        component: () => import('@/views/front/CityLayout.vue'),
        children: [
          {
            path: ':slug',
            name: 'City',
            component: () => import('@/views/front/City.vue'),
          },
          // {
          //   path: ':slug/artists',
          //   name: 'Artists',
          //   component: () => import('@/views/front/Artists.vue'),
          // },
          {
            path: ':slug/artists',
            name: 'Artists',
            component: () => import('@/views/front/Artists.vue'),
          },
          {
            path: ':slug/youth-current-artists',
            name: 'YoungCurrentArtists',
            component: () => import('@/views/front/ArtistsYoung.vue'),
          },
          {
            path: 'hong-kong/artist/:slug',
            name: 'HKArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: 'macao/artist/:slug',
            name: 'MOArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: 'beijing/artist/:slug',
            name: 'BJArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: 'san-francisco/artist/:slug',
            name: 'SFArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: 'tallinn/artist/:slug',
            name: 'TLLArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: 'rome/artist/:slug',
            name: 'RMArtist',
            component: () => import('@/views/front/Artist.vue'),
          },
          {
            path: ':slug/phy-xhibition',
            name: 'Phy-xhibition',
            component: () => import('@/views/front/Phyxhibition.vue'),
          },
          {
            path: ':slug/virtual-exhibition',
            name: 'VirtualExhibition',
            component: () => import('@/views/front/VirtualExhibition.vue'),
          },
        ],
      },
      {
        path: 'coming-soon',
        name: 'ComingSoon',
        component: () => import('@/views/front/ComingSoon.vue'),
      },
      {
        path: 'global-screening',
        name: 'GlobalScreening',
        component: () => import('@/views/front/GlobalScreening.vue'),
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/front/About.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('@/views/front/Contact.vue'),
      },
      {
        path: 'acknowledgement',
        name: 'Acknowledgement',
        component: () => import('@/views/front/Acknowledgement.vue'),
      },
      {
        path: 'testInfiniteScrolling',
        name: 'testInfiniteScrolling',
        component: () => import('@/views/front/InfiniteScrolling.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    // always scroll to top
    return { top: 0 };
  },
});

trackRouter(router);

export default router;
