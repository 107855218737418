import { createApp, h } from 'vue';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

import axios from 'axios';
import VueAxios from 'vue-axios';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueLottiePlayer from 'vue-lottie-player';
import VueGtag from 'vue-gtag-next';

import App from './App.vue';
import router from './router';
import store from './store';

const httpLink = new HttpLink({
  // uri: 'http://localhost:3001/graphql',
  uri: process.env.VUE_APP_GRAPHQL_URL,
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

// const app = createApp(App);
const app = createApp({
  render: () => h(App),
});
app.use(createApolloProvider);
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    // See 'apollo' definition
    // For example: default query options
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'cache-and-network',
    },
  },
  // Global error handler for all smart queries and subscriptions
  errorHandler(error) {
    console.log('Global error handler');
    console.error(error);
  },
});
app.use(apolloProvider);
app.use(VueAxios, axios);
app.use(VueLoading);
app.use(VueLottiePlayer);
app.use(VueGtag, {
  property: {
    id: 'G-VCZG6E1NT6',
    params: {
      user_id: '199244725',
      send_page_view: true,
      linker: {
        domain: ['citya.space'],
      },
    },
  },
});
app.use(router);
app.use(store);
app.mount('#app');
