import { createStore } from 'vuex';

export default createStore({
  state: {
  },
  mutations: {
    setTime(state, value) {
      state.time = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
